<template>
    <div class="sms">
        <div class="sms_img">
            <img v-for="(img, index) in topImgs" @click="seeVideo(index == 1)" :key="index" :src="img" alt="">
        </div>
        <div class="sms_swiper">
          <swiper
            :slides-per-view="1"
            :pagination = "true"
            autoplay>
            <swiper-slide v-for="(item, index) in swiperImg" :key="index">
              <div @click="goHome" class="slider_box">
                <img :src="item.img"/>
              </div>
            </swiper-slide>
            </swiper>
        </div>
        <div class="sms_img">
            <img v-for="(img, index) in bottomImgs" :key="index" :src="img" alt="">
        </div>
        <div class="sms_step3">
            <div class="sms_step3_img">
                <img src="../../assets/imgs/sms/step_3_icon.png"/>
            </div>
            <div @click="goSave" class="sms_step3_button">
                <text>点我查看</text>
                <text>保存方法</text>
            </div>
        </div>
        <div class="sms_contact">
            客服咨询电话: 021-64175076
        </div>
        <div class="sms_bottom">
            <a href="tel:021-64175077">客服电话</a>
            <div class="sms_bottom_button" @click="goHome">
                <img src="../../assets/imgs/sms/handdle_button.gif"/>
            </div>
        </div>
        <!-- 视频 -->
        <div class="video_wrap" v-if="showVideo" @click.stop="seeVideo(!showVideo)">
            <div class="video_info">
                <span @click.stop="seeVideo(false)" class="video_info_icon"></span>
                <video loop="loop" autoplay="autoplay" controls="controls" src="../../assets/video/introduce.mp4"/>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import { jumpTo, userOperationEvent, getQueryStr } from '../../utils/util';
SwiperCore.use([Autoplay, Pagination])
export default {
    name: '',
    components: {
            Swiper,
            SwiperSlide,
    },
    setup() {
        const state= reactive({
            topImgs: [
                require('../../assets/imgs/home_head_bg.png'),
                require('../../assets/imgs/sms/step_1.png')
            ],
            bottomImgs: [
                require('../../assets/imgs/sms/step_3.png')
            ],
            swiperImg: [
                {img: require('../../assets/imgs/sms/choose_num.png')},
                {img: require('../../assets/imgs/sms/send_message.png')},
                {img: require('../../assets/imgs/sms/pay_for.png')},
                {img: require('../../assets/imgs/sms/withdraw.png')},
            ],
            paginationInfo: {
                dynamicBullets: true
            },
            showVideo: false
        })
        function goHome() {
            userOperationEvent(6, getQueryStr('a'), {})
            jumpTo('/?login=true')
        }
        function goSave() {
            jumpTo('/saveDesk')
        }
        function loadData() {
            userOperationEvent(1, getQueryStr('a'), {})
        }
        function seeVideo(show) {
            state.showVideo = show
        }
        loadData()
        return {
            ...toRefs(state),
            goHome,
            goSave,
            seeVideo
        }
    },
}
</script>
<style>
.swiper-pagination-bullet-active {
    background: red !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
}
</style>
<style lang='scss' scoped>
$button-color: #ffefd8;
.sms {
    background: url('../../assets/imgs/sms/bg.png') center center repeat-y;
    background-size: contain;
    &_img {
        font-size: 0;
        img {
            max-width: 100%;
        }
    }
    &_swiper {
        overflow: hidden;
        border-radius: $box-radius-middle;
        margin: 0px 25px 10px 25px;
        box-sizing: border-box;
        .slider_box {
            max-width: 100%;
            background: #efefef;  
            line-height: 0;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    &_step3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 60%;
        margin: 0 30px;
        img {
            max-width: 90%;
        }
        &_button {
            display: inline-block;
            width: 120px;
            padding: 6px 17px;
            color: $button-color;
            border-radius: 5px;
            background: linear-gradient(to top, $theme-dark, $theme-light);
            text-align: center;
            font-weight: 700;
        }
    }
    &_contact {
        color: #945d35;
        a {
            color: #945d35;
        }
        padding: 20px 30px 78px 30px;
        font-size: 20px;
        font-weight: 700;
    }
    &_bottom {
        position: fixed;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 20px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        a {
            color: #000;
            position: relative;
            font-size: $font-less;
            &::after {
                content: '';
                position: absolute;
                width: 27px;
                height: 27px;
                background: url('../../assets/imgs/sms/server.png') center center no-repeat;
                background-size: contain;
                left: 50%;
                transform: translateX(-50%);
                bottom: 1.2em;
            }
        }
        .sms_bottom_button {
            width: 80%;
            img {
                max-width: 100%;
            }
        }
    }
}
.video_wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.8);
    z-index: 9;
    overflow: auto;
    .video_info {
        position: absolute;
        width: 90%;
        height: auto;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);
        video {
            width: 100%;
            height: auto;
        }
         &_icon {
        position: absolute;
            width: 30px;
            height: 30px;
            background: rgba($color: #fff, $alpha: 0.35) url('../../assets/imgs/cross_white.png') center center no-repeat;
            background-size: 50%;
            border-radius: 50%;
            right: -15px;
            top: -15px;
            z-index: 99;
        }
    }
}
</style>